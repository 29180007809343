@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

:root {
  --text-shadow-setting: 2px 2px 10px #000000;
  --main-yellow: goldenrod;
  --main-blue: rgb(54,127,190);
}

*{
  margin: 0 auto;
  padding: 0;
}

body {
  width: 100%;
  background-image: url('../src/assets/images/costarica.jpg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.page-container {
  background-color: rgba(0,0,0,0.17);
}

.jumbotron {
  background-color: transparent;
  height: 450px;
  text-shadow: var(--text-shadow-setting);
  border-radius: 0;
}

.jumbotron h1 {
  font-size: 65px;
  letter-spacing: 3px;
}

.section-title {
  text-shadow: var(--text-shadow-setting);
  letter-spacing: 2px;
  font-size: 35px;
}

img {
  height: 280px;
  margin-right: 30px
}

#about {
  min-height: 300px;
  background-color: var(--main-blue);
}

.project-container {
  min-height: 380px;
}

#agotb-section {
  background-color: #0E86A8;
}

#foodbook-section {
  background-color: #75A2CA;
}

#wayfarer-section {
  background-color: #275D8C;
}

#toolodex-section {
  background-color: #122A40;
}

#song-quiz-section {
  background-color: #253340;
}

#skills-section {
  background-color: #367FBE;
}

i {
  font-size: 40px;
}

.skills-grid {
  max-width: 60%;
}

.skills-grid i {
  font-size: 70px;
}

.project-img {
  height: 27vw;
  max-height: 330px;
  min-height: 235px;
}

.img-grow {
  transition: linear .2s;
}

.img-grow:hover {
  transform: scale(1.05);
}

.project-title{
  font-size: 38px;
  line-height: 1;
}

.project-description {
  font-size: 17px;
}

.project-link {
  color: var(--main-yellow);
}

.project-link:hover {
  color: whitesmoke;
  text-decoration: none;
}

.btn {
  color: var(--main-blue);
  margin-right: 10px;
  font-weight: bold;
  border-radius: 0;
}

.btn:hover {
  color: var(--main-yellow);
}

#envelope {
  font-size: 25px;
}

#email {
  float: left;
  font-size: 20px;
}

#languages {
  line-height: 1;
}

.flag {
  cursor: default;
}

.pride {
  font-size: 25px;
  line-height: 1;
}

#copyright {
  text-shadow: var(--text-shadow-setting);
  font-size: 17px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 991px) {
  .container {
    padding-left: 0;
    padding-right: 0;
    margin-right: 25px;
    margin-left: 25px;
    max-width: 100vw;
  }

  .skills-grid {
    max-width: 70%;
  }
}

@media only screen and (max-width: 980px) {
  .project-description, .lead, .btn, #email {
    font-size: 15px;
  }

  #envelope {
    font-size: 20px;
  }

  .project-tech-logos i {
    font-size: 38px;
  }

  .project-title {
    font-size: 34px;
  }

  p.mb-2 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .jumbotron {
    height: auto;
  }

  #about-content {
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  #about-content img {
    max-height: 200px;
    margin: 10px auto;
  }

  #about-content h2, p {
    text-align: center;
  }

  .project {
    display: flex;
    flex-direction: column;
  }

  .project-title{
    text-align: center;
  }

  .project-img {
    max-height: 280px;
    max-width: 80vw;
    margin: 20px auto 0;
  }

  .project-tech-logos {
    margin-bottom: 10px;
  }

  .btn {
    margin: 0 5px;
    font-size: larger;
  }

  #email {
    float: none;
    justify-content: center;
  }

  .skills-grid {
    max-width: 80%;
  }
}

@media only screen and (max-width: 471px) {
  .project-img {
    height: auto;
  }

}
